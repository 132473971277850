import { useTranslation } from 'react-i18next';
import { formattedUserFacingDateTime } from 'DateHelpers';
import { type ProgramApplication, ProgramApplicationStatus } from 'ProgramApplication';
import { type UserInAdmin } from 'Users';
import 'UserAdministrationTab/UserAdminItemCard/UserAdminItemCard.styles.scss';
import './AdminInformation.scss';
import { getProgramFamilyFormDatum } from 'ProgramFamilyFormData';
import { IdTableRow } from './IdTableRow';

interface Props {
    programApplication: ProgramApplication<true>;
    user?: UserInAdmin;
    country?: string;
}

export function ProgramApplicationInformation({ programApplication, user, country }: Props) {
    const { t } = useTranslation('back_royal');

    const {
        id,
        appliedAt,
        admissionsDecision,
        admissionsInterviewer,
        admissionsInterviewDate,
        admissionsFinalDecision,
        admissionsMotivationAssessmentScore,
        admissionsContributionAssessmentScore,
        admissionsInsightfulnessAssessmentScore,
        admissionsMotivationScore,
        admissionsContributionScore,
        admissionsInsightfulnessScore,
    } = programApplication;

    const programFamilyFormDatum = user && getProgramFamilyFormDatum(user, programApplication.programFamily);

    return (
        <div className="information-container">
            <div className="information-table" data-testid={`ProgramApplicationInformation: ${id}`}>
                <IdTableRow id={id} />
                <div className="information-panel-row">
                    <div className="row-key">Applied At</div>
                    <div className="row-val">{formattedUserFacingDateTime(1000 * appliedAt)}</div>
                </div>
                {admissionsDecision && (
                    <div className="information-panel-row">
                        <div className="row-key">Admissions Decision</div>
                        <div className="row-val">{admissionsDecision}</div>
                    </div>
                )}
                {admissionsInterviewer && (
                    <div className="information-panel-row">
                        <div className="row-key">Admissions Interviewer</div>
                        <div className="row-val">{admissionsInterviewer}</div>
                    </div>
                )}
                {admissionsInterviewDate && (
                    <div className="information-panel-row">
                        <div className="row-key">Interview Date</div>
                        <div className="row-val">{formattedUserFacingDateTime(1000 * admissionsInterviewDate)}</div>
                    </div>
                )}
                {(admissionsMotivationAssessmentScore || admissionsMotivationScore) && (
                    <div className="information-panel-row">
                        <div className="row-key">Motivation</div>
                        <div className="row-val">
                            {admissionsMotivationAssessmentScore || admissionsMotivationScore}
                        </div>
                    </div>
                )}
                {(admissionsContributionAssessmentScore || admissionsContributionScore) && (
                    <div className="information-panel-row">
                        <div className="row-key">Contribution</div>
                        <div className="row-val">
                            {admissionsContributionAssessmentScore || admissionsContributionScore}
                        </div>
                    </div>
                )}
                {(admissionsInsightfulnessAssessmentScore || admissionsInsightfulnessScore) && (
                    <div className="information-panel-row">
                        <div className="row-key">Insightfulness</div>
                        <div className="row-val">
                            {admissionsInsightfulnessAssessmentScore || admissionsInsightfulnessScore}
                        </div>
                    </div>
                )}
                {admissionsFinalDecision && (
                    <div className="information-panel-row">
                        <div className="row-key">Admissions Final Decision</div>
                        <div className="row-val">{admissionsFinalDecision}</div>
                    </div>
                )}
                {country && (
                    <div className="information-panel-row">
                        <div className="row-key">Country</div>
                        <div className="row-val">{t(`countries.country.${country}`)}</div>
                    </div>
                )}
                {programApplication.status === ProgramApplicationStatus.SubmittedApplication && programFamilyFormDatum && (
                    <>
                        {programFamilyFormDatum.declineScholarshipConsideration && (
                            <div className="information-panel-row">
                                <div className="row-key">Scholarships Interested In</div>
                                <div className="row-val">Declined</div>
                            </div>
                        )}
                        {!programFamilyFormDatum.declineScholarshipConsideration && (
                            <div className="information-panel-row">
                                <div className="row-key">Scholarships Interested In</div>
                                <div className="row-val">
                                    {programFamilyFormDatum.scholarshipLabelsInterestedIn.join(', ')}
                                </div>
                            </div>
                        )}
                        {programFamilyFormDatum.shortAnswerScholarship && (
                            <div className="information-panel-row">
                                <div className="row-key">Scholarship Circumstances</div>
                                <div className="information-panel-row">
                                    <div>{programFamilyFormDatum.shortAnswerScholarship}</div>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

export default ProgramApplicationInformation;
