import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { uniqueId } from 'lodash/fp';
import { Button } from '@mui/material';
import { FileDownload, FILE_DOWNLOAD_ERROR_MSG, Select } from 'FrontRoyalMaterialUiForm';
import { FormProvider, useForm, yupResolver } from 'FrontRoyalReactHookForm';
import { hasGraduated, HumanReadableAcademicProbationStatus } from 'ProgramInclusion';
import { formattedUserFacingDateTime, formattedUserFacingMonthDay } from 'DateHelpers';
import { useCohorts } from 'UserAdministrationTab/redux';
import getAdminDisplayString from 'UserAdministrationTab/utils';
import { formatMoney } from 'FormatMoney';
import * as Yup from 'yup';
import { type Nullable } from '@Types';
import { useAngularContext } from 'AngularContext';
import { type DownloadableStudentDocsHelperClass } from 'DownloadableStudentDocsHelper';
import { type CohortClass } from 'Cohorts';
import 'UserAdministrationTab/UserAdminItemCard/UserAdminItemCard.styles.scss';
import { getProgramConfigValue } from 'Program';
import { type ProgramInclusionInformationProps } from './ProgramInclusionInformation.types';
import './AdminInformation.scss';
import { IdTableRow } from './IdTableRow';

type FormFields = {
    transcriptSelect: string;
};

type TranscriptOptionValue = {
    format: 'digital' | 'printable';
    type: 'official' | 'unofficial';
};

type TranscriptOption = {
    label: string;
    value: TranscriptOptionValue;
};

const transcriptOptions = [
    { label: 'Official Printable', value: { format: 'printable', type: 'official' } } as TranscriptOption,
    { label: 'Official Digital', value: { format: 'digital', type: 'official' } } as TranscriptOption,
    { label: 'Unofficial Digital', value: { format: 'digital', type: 'unofficial' } } as TranscriptOption,
];

export function ProgramInclusionInformation({ programInclusion, user }: ProgramInclusionInformationProps) {
    const $injector = useAngularContext();
    const Cohort = $injector.get<CohortClass>('Cohort');
    const DownloadableStudentDocsHelper = $injector.get<DownloadableStudentDocsHelperClass>(
        'DownloadableStudentDocsHelper',
    );
    const downloadableStudentDocsHelper = new DownloadableStudentDocsHelper();

    const [downloadingEnrollmentVerificationLetter, setDownloadingEnrollmentVerificationLetter] = useState(false);
    const [enrollmentVerificationLetterDownloadError, setEnrollmentVerificationLetterDownloadError] = useState('');
    const [downloadingProgressReport, setDownloadingProgressReport] = useState(false);
    const [progressReportDownloadError, setProgressReportDownloadError] = useState('');
    const [downloadingTranscript, setDownloadingTranscript] = useState(false);
    const [transcriptDownloadError, setTranscriptDownloadError] = useState('');

    const formValidationSchema = Yup.object().shape({
        transcriptSelect: Yup.object().shape({
            format: Yup.string().required(),
            type: Yup.string().required(),
        }),
    });

    const formFunctions = useForm<FormFields>({
        resolver: yupResolver(formValidationSchema),
        defaultValues: { transcriptSelect: undefined },
    });

    const { watch } = formFunctions;
    const transcriptSelect = watch('transcriptSelect');

    const {
        admissionOfferId,
        includedCohortSectionId,
        cohortSlackRoomId,
        includedAt,
        id: programInclusionId,
        tuitionContractId,
        willNotGraduateReason,
        disableExamLocking,
        honorsIneligible,
        canUseDeferralLinkUntil,
        historicalCohortIds,
        graduatedWithHonors,
        academicHold,
        academicProbationStatus,
    } = programInclusion;

    const { id: userId, admissionOffers, tuitionContracts, country } = user;

    const { cohortsById, availableCohorts } = useCohorts();
    const admissionOffer = admissionOffers.find(offer => offer.id === admissionOfferId)!;
    const userTuitionContract = tuitionContracts?.find(contract => contract.id === tuitionContractId);
    const tuitionBenefitAdministrator = userTuitionContract ? userTuitionContract.tuitionBenefitAdministrator : null;

    const { netRequiredPayment, netTuitionAmount, netPaymentFeeAmount } = userTuitionContract || {};
    const showPaymentInfo = typeof netRequiredPayment === 'number' && typeof netTuitionAmount === 'number';
    const showPaymentFees = showPaymentInfo && typeof netPaymentFeeAmount === 'number' && netPaymentFeeAmount > 0;

    const offerCohort = cohortsById && admissionOffer && cohortsById?.[admissionOffer.cohortId];
    const cohort = cohortsById && cohortsById?.[programInclusion.cohortId];
    const section = cohort?.cohortSections?.find(sec => sec.id === includedCohortSectionId);
    const slackRoom = cohort?.slackRooms?.find(room => room.id === cohortSlackRoomId);
    const historicalCohorts = availableCohorts ? historicalCohortIds.map(cohortId => cohortsById[cohortId]) : [];

    const stripeEnvModifier = process.env.NODE_ENV === 'production' ? '' : 'test/';
    const stripeUrl = `https://dashboard.stripe.com/${stripeEnvModifier}customers/${userId}`;

    const { t } = useTranslation('back_royal');
    const [linkJustCopied, setLinkJustCopied] = useState(false);

    async function copyDeferralLink() {
        await navigator.clipboard.writeText(`${window.ENDPOINT_ROOT}/deferral/${programInclusionId}`);

        setLinkJustCopied(true);
        setTimeout(() => setLinkJustCopied(false), 2000);
    }

    async function downloadEnrollmentVerificationLetter() {
        setEnrollmentVerificationLetterDownloadError('');
        setDownloadingEnrollmentVerificationLetter(true);
        try {
            await downloadableStudentDocsHelper.downloadSupplementalDocument(
                user,
                'enrollment_verification_letter',
                programInclusion.id,
            );
        } catch (err) {
            setEnrollmentVerificationLetterDownloadError(FILE_DOWNLOAD_ERROR_MSG);
        }
        setDownloadingEnrollmentVerificationLetter(false);
    }

    async function downloadProgressReport() {
        setProgressReportDownloadError('');
        setDownloadingProgressReport(true);
        try {
            await downloadableStudentDocsHelper.downloadSupplementalDocument(
                user,
                'progress_report',
                programInclusion.id,
            );
        } catch (err) {
            setProgressReportDownloadError(FILE_DOWNLOAD_ERROR_MSG);
        }
        setDownloadingProgressReport(false);
    }

    const disableTranscriptDownload = useCallback(
        () => downloadingTranscript || !findTranscriptOption(transcriptSelect),
        [downloadingTranscript, transcriptSelect],
    );

    function findTranscriptOption(optionValue: Nullable<string>) {
        if (!optionValue) {
            return null;
        }

        const optionVal = JSON.parse(optionValue);
        return transcriptOptions?.find(
            opt => opt.value.format === optionVal.format && opt.value.type === optionVal.type,
        );
    }

    async function downloadTranscript() {
        const value = findTranscriptOption(transcriptSelect)?.value;
        if (!value) {
            return;
        }

        setTranscriptDownloadError('');
        setDownloadingTranscript(true);
        try {
            await downloadableStudentDocsHelper.downloadTranscript(
                user,
                programInclusion.cohortId,
                value.format,
                value.type,
            );
        } catch (err) {
            setTranscriptDownloadError(FILE_DOWNLOAD_ERROR_MSG);
        }
        setDownloadingTranscript(false);
    }

    return (
        <FormProvider {...formFunctions}>
            <form>
                <div className="information-container">
                    <div
                        className="information-table"
                        data-testid={`ProgramInclusionInformation: ${programInclusionId}`}
                    >
                        <IdTableRow id={programInclusionId} />
                        <div className="information-panel-row">
                            <div className="row-key">Included At</div>
                            <div className="row-val">{formattedUserFacingDateTime(1000 * includedAt)}</div>
                        </div>
                        {offerCohort && (
                            <div className="information-panel-row">
                                <div className="row-key">Offer Cohort</div>
                                <div className="row-val">{offerCohort.name}</div>
                            </div>
                        )}
                        {canUseDeferralLinkUntil && new Date(1000 * canUseDeferralLinkUntil) > new Date() && (
                            <div className="information-panel-row">
                                <div className="row-key">Deferral Link</div>
                                <div className="row-val">
                                    <Button
                                        variant="text"
                                        onClick={() => copyDeferralLink()}
                                        title="Copy link to clipboard"
                                        color="primary"
                                    >
                                        Expires on {formattedUserFacingMonthDay(1000 * canUseDeferralLinkUntil, true)}
                                        &nbsp;&nbsp;
                                        {linkJustCopied ? (
                                            <i className="fa fa-check" data-testid="deferral-link-check" />
                                        ) : (
                                            <i className="fa fa-clipboard" data-testid="deferral-link-clipboard" />
                                        )}
                                    </Button>
                                </div>
                            </div>
                        )}
                        <div className="information-panel-row">
                            <div className="row-key">Scholarship Name</div>
                            <div className="row-val">{getAdminDisplayString(userTuitionContract?.scholarships)}</div>
                        </div>
                        {showPaymentInfo && (
                            <div className="information-panel-row">
                                <div className="row-key">Tuition Status</div>
                                <div className="row-val">
                                    <div className="payment-status-info">
                                        <p>Required:</p>
                                        <p>{formatMoney(netRequiredPayment)}</p>
                                    </div>
                                    <div className="payment-status-info">
                                        <p>Paid:</p>
                                        <p>{formatMoney(netTuitionAmount)}</p>
                                    </div>
                                    <div className="payment-status-info">
                                        <p>Remaining:</p>
                                        <p>{formatMoney(netRequiredPayment - netTuitionAmount)}</p>
                                    </div>
                                    {showPaymentFees && (
                                        <div className="payment-status-info">
                                            <p>Payment Fees:</p>
                                            <p>{formatMoney(netPaymentFeeAmount)}</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        {tuitionBenefitAdministrator && (
                            <div className="information-panel-row">
                                <div className="row-key">Tuition Benefit Administrator</div>
                                <div className="row-val">{tuitionBenefitAdministrator}</div>
                            </div>
                        )}
                        {section && (
                            <div className="information-panel-row">
                                <div className="row-key">Section</div>
                                <div className="row-val">{t(`cohort_sections.${section.identifier}_short`)}</div>
                            </div>
                        )}
                        {slackRoom && (
                            <div className="information-panel-row">
                                <div className="row-key">Slack Room</div>
                                <div className="row-val">{slackRoom.title}</div>
                            </div>
                        )}
                        {historicalCohorts.length > 0 && (
                            <div className="information-panel-row">
                                <div className="row-key">Deferral History</div>
                                <div className="row-val">
                                    {historicalCohorts.map(({ name, id }) => (
                                        <p key={uniqueId(id)}>{name}</p>
                                    ))}
                                </div>
                            </div>
                        )}
                        {willNotGraduateReason && (
                            <div className="information-panel-row">
                                <div className="row-key">Expulsion/Withdrawal Reason</div>
                                <div className="row-val">{willNotGraduateReason.title}</div>
                            </div>
                        )}
                        <div className="information-panel-row">
                            <div className="row-key">Disable Exam Locking</div>
                            <div className="row-val">{disableExamLocking ? 'true' : 'false'}</div>
                        </div>
                        {hasGraduated(programInclusion) ? (
                            <div className="information-panel-row">
                                <div className="row-key">Graduated with Honors</div>
                                <div className="row-val">{graduatedWithHonors ? 'true' : 'false'}</div>
                            </div>
                        ) : (
                            <div className="information-panel-row">
                                <div className="row-key">Ineligible for honors</div>
                                <div className="row-val">{honorsIneligible ? 'true' : 'false'}</div>
                            </div>
                        )}
                        <div className="information-panel-row">
                            <div className="row-key">On Administrative Hold</div>
                            <div className="row-val"> {academicHold ? 'true' : 'false'}</div>
                        </div>
                        {academicProbationStatus && (
                            <div className="information-panel-row">
                                <div className="row-key">Academic Probation Status</div>
                                <div className="row-val" style={{ whiteSpace: 'nowrap' }}>
                                    {HumanReadableAcademicProbationStatus[academicProbationStatus]}
                                </div>
                            </div>
                        )}
                        <div className="information-panel-row">
                            <div className="row-key">View Stripe Details</div>
                            <div className="row-val">
                                <a href={stripeUrl} target="_blank" rel="noreferrer">
                                    <i className="fa fa-credit-card" />
                                </a>
                            </div>
                        </div>
                        {country && (
                            <div className="information-panel-row">
                                <div className="row-key">Country</div>
                                <div className="row-val">{t(`countries.country.${country}`)}</div>
                            </div>
                        )}
                        {getProgramConfigValue(
                            programInclusion.programType,
                            'supportsEnrollmentVerificationLetter',
                        ) && (
                            <div className="information-panel-row" data-testid="evLetterRow">
                                <div className="row-key">Enrollment Verification Letter</div>
                                <div className="row-val file-download-container">
                                    <FileDownload
                                        name="evLetterDownload"
                                        data-testid="evLetterDownload"
                                        sx={{ padding: 0 }}
                                        onClick={() => downloadEnrollmentVerificationLetter()}
                                        downloading={downloadingEnrollmentVerificationLetter}
                                        CircularProgressProps={{
                                            'data-testid': 'evLetterDownloadSpinner',
                                        }}
                                    />
                                    {enrollmentVerificationLetterDownloadError && (
                                        <div className="red error-msg">{enrollmentVerificationLetterDownloadError}</div>
                                    )}
                                </div>
                            </div>
                        )}
                        {Cohort.supportsProgressReport(programInclusion.programType) && (
                            <div className="information-panel-row" data-testid="progressReportRow">
                                <div className="row-key">Progress Report</div>
                                <div className="row-val file-download-container">
                                    <FileDownload
                                        name="progressReportDownload"
                                        data-testid="progressReportDownload"
                                        sx={{ padding: 0 }}
                                        onClick={() => downloadProgressReport()}
                                        downloading={downloadingProgressReport}
                                        CircularProgressProps={{
                                            'data-testid': 'progressReportDownloadSpinner',
                                        }}
                                    />
                                    {progressReportDownloadError && (
                                        <div className="red error-msg">{progressReportDownloadError}</div>
                                    )}
                                </div>
                            </div>
                        )}
                        <div className="information-panel-row" data-testid="transcriptsRow">
                            <div className="row-key">Transcripts</div>
                            <div className="row-val file-download-container">
                                <div className="transcript-select-container">
                                    <Select
                                        name="transcriptSelect"
                                        className="transcript-select"
                                        label="Choose transcript"
                                        options={transcriptOptions}
                                        optionLabel={option => option.label}
                                        optionValue={option => JSON.stringify(option.value)}
                                        renderValue={value => findTranscriptOption(value as string)?.label}
                                        size="small"
                                    />
                                    <FileDownload
                                        name="transcriptDownload"
                                        data-testid="transcriptDownload"
                                        sx={{ padding: 0 }}
                                        onClick={() => downloadTranscript()}
                                        disabled={disableTranscriptDownload()}
                                        downloading={downloadingTranscript}
                                        CircularProgressProps={{
                                            'data-testid': 'transcriptDownloadSpinner',
                                        }}
                                    />
                                </div>
                                {transcriptDownloadError && (
                                    <div className="red error-msg">{transcriptDownloadError}</div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </FormProvider>
    );
}

export default ProgramInclusionInformation;
