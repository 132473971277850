
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.chat = window.Smartly.locales.modules.en.back_royal.chat || {};
window.Smartly.locales.modules.en.back_royal.chat.chat = window.Smartly.locales.modules.en.back_royal.chat.chat || {};
window.Smartly.locales.modules.en.back_royal.chat.chat = {...window.Smartly.locales.modules.en.back_royal.chat.chat, ...{
    "how_can_i_help": "How can I help?",
    "message_ai_tutor": "Message {AI} Tutor",
    "new_topic": "New Topic",
    "submit_message": "Submit Message",
    "cancel_response": "Cancel",
    "ai_welcome_message_notice": "Have a question? AI Advisor is ready to help. Note that conversation <a class=\"text-white underline\" href=\"{faqUrl}\" target=\"_blank\">message history</a> may be used to improve future bot interactions. ",
    "ai_welcome_message_notice_no_link": "Have a question? AI Advisor is ready to help. Note that conversation message history may be used to improve future bot interactions. ",
    "lesson_player_welcome_message_notice": "Have a question about coursework? Chat with AI Advisor for tutoring help and extra practice. Note that conversation <a class=\"text-white underline\" href=\"{faqUrl}\" target=\"_blank\">message history</a> may be used to improve future bot interactions.",
    "lesson_player_welcome_message_notice_no_link": "Have a question about coursework? Chat with AI Advisor for tutoring help and extra practice. Note that conversation message history may be used to improve future bot interactions.",

    "ai_welcome_message_stay_tuned": "Stay tuned for upgrades and new ways to interact!",
    "learn_more": "Learn More:",
    "rate_thumbs_up": "Rate this answer with thumbs up.",
    "rate_thumbs_down": "Rate this answer with thumbs down.",
    "error": "Oops. Something went wrong. Please try again.",
    "exam_in_progress_message": "Hi, {name}! Our chat will be available again once you've completed the {examTitle}. Best of luck!",
    "locked_welcome_modal": "Admitted {institutionName} students and alumni gain access to AI Advisor, an advanced chatbot built to support their lifelong learning journey.",
    "locked_welcome_modal_cta_message_signup_headline": "Next application deadline:",
    "locked_welcome_modal_cta_message_signup_subtext": "{applicationDeadline}",
    "locked_welcome_modal_cta_button_text_signup": "Apply Now",
    "locked_welcome_modal_cta_message_applied_interview_pending_headline": "Schedule your interview:",
    "locked_welcome_modal_cta_button_text_applied_interview_pending": "Schedule",
    "locked_welcome_modal_cta_message_offered_admission_headline": "Registration deadline:",
    "locked_welcome_modal_cta_message_offered_admission_subtext": "{registrationDeadline}",
    "locked_welcome_modal_cta_button_text_offered_admission": "Register",
    "locked_unavailable": "The AI Advisor is currently unavailable, we are sorry for the inconvenience.",
    "available_to_students_and_alumni": "Available to students and alumni",
    "ai_advisor": "AI Advisor",
    "ai_advisor_can_make_mistakes": "AI Advisor can make mistakes. Consider checking important information.",
    "ai_tutor_can_make_mistakes": "AI Tutor can make mistakes. Consider checking important information."
}
}
    