import 'angular-bind-html-compile';
import 'EventLogger/angularModule';
import { react2Angular } from 'FrontRoyalReact2Angular';
import 'IsMobile/angularModule';
import 'Navigation/angularModule';
import 'Translation/angularModule';
import { LessonPlayerBot } from '../../LessonPlayerBot/LessonPlayerBot';

const angularModule = angular.module('FrontRoyal.TutorBot', [
    'angular-bind-html-compile',
    'FrontRoyal.Navigation',
    'Translation',
    'EventLogger',
    'isMobile',
]);

angularModule.component(
    'lessonPlayerBot',
    react2Angular(LessonPlayerBot, ['currentUser', 'clientContext', 'initialMessage'], 'h-full'),
);

export default angularModule;
